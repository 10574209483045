export function getInstruccionesEjercicioAV(claveEjercicio) {
    if (claveEjercicio == 'AVP1') return instruccionesAV1();
    if (claveEjercicio == 'AVL1') return instruccionesAV1();
    if (claveEjercicio == 'AVH1') return instruccionesAV1();
    if (claveEjercicio == 'AVP2') return instruccionesAVP2();
    return []
}

function instruccionesAV1() {
    return [{
            img: '',
            titulo: 'Prestar atención',
            texto: "Aparecerá una serie de tres letras en forma aleatoria pulsando al centro de la pantalla y se separarán entre sí durante el ejercicio."
        },
        {
            img: '',
            titulo: 'Enfocarse en un punto',
            texto: "Deberás fijar tu vista en el circulo que estará al centro de las letras e identificar todo el conjunto. Este paso se debe realizar SIN mover los ojos lateralmente."
        },
        {
            img: '',
            titulo: 'Presionar botón',
            texto: 'En la parte inferior de la pantalla, aparecerá el botón "Iguales" el cual presionarás cada vez que el conjunto de letras sean idénticos. No importa si las letras han desaparecido, tu click cuenta aunque no estén presentes.'
        },
        {
            img: '',
            titulo: 'Sentarse adecuadamente',
            texto: 'Recuerda sentarte correctamente.'
        },
        {
            img: '',
            titulo: 'No usar la boca',
            texto: 'El proceso de lectura dinámica requiere solamente del cerebro y ojos, los cuales son mucho más rápidos que el habla. Por lo tanto es muy importante NO VOCALIZAR ningún contenido ya que esto hará que tu cerebro se distraiga. Mantén la boca cerrada y no muevas la lengua.'
        },
        {
            img: '',
            titulo: 'Relájate',
            texto: 'Relaja tu vista y confía en tu cerebro, te sorprenderás de los resultados.'
        },
        {
            img: '',
            titulo: 'Descansa',
            texto: 'Este ejercicio puede ser cansado para tus ojos por lo que te recomendamos, al finalizar, descansar tu vista un minuto.'
        },
    ]
}

function instruccionesAVP2() {
    return [{
            img: '',
            titulo: 'Prestar atención',
            texto: "Aparecerá una serie de tres letras en forma aleatoria pulsando al centro de la pantalla y se separarán entre sí durante el ejercicio."
        },
        {
            img: '',
            titulo: 'Enfocarse en un punto',
            texto: "Deberás fijar tu vista en el circulo que estará al centro de las letras e identificar todo el conjunto. Este paso se debe realizar SIN mover los ojos lateralmente."
        },
        {
            img: '',
            titulo: 'Presionar botón',
            texto: 'En la parte inferior de la pantalla, aparecerá el botón "Iguales" el cual presionarás cada vez que el conjunto de letras sean idénticos. No importa si las letras han desaparecido, tu click cuenta aunque no estén presentes.'
        },
        {
            img: '',
            titulo: 'Sentarse adecuadamente',
            texto: 'Recuerda sentarte correctamente.'
        },
        {
            img: '',
            titulo: 'No usar la boca',
            texto: 'El proceso de lectura dinámica requiere solamente del cerebro y ojos, los cuales son mucho más rápidos que el habla. Por lo tanto es muy importante NO VOCALIZAR ningún contenido ya que esto hará que tu cerebro se distraiga. Mantén la boca cerrada y no muevas la lengua.'
        },
        {
            img: '',
            titulo: 'Relájate',
            texto: 'Relaja tu vista y confía en tu cerebro, te sorprenderás de los resultados.'
        },
        {
            img: '',
            titulo: 'Descansa',
            texto: 'Este ejercicio puede ser cansado para tus ojos por lo que te recomendamos, al finalizar, descansar tu vista un minuto.'
        },
    ]
}