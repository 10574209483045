<template>
  <div>
    <v-row justify="center">
      <v-col cols="4">
        <v-card :dark="modoOscuro" outlined color="white">
          <v-list
            outlined
            :color="modoOscuro ? '#385F73' : 'white'"
          >
            <v-subheader class="h3">Resultados:</v-subheader>
            <v-divider class="mx-3"></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Correctas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ correctas }}</v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="red">mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Incorrectas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ incorrectas }}</v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="orange">mdi-alert-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Omitidas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ omitidas }}</v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue">mdi-chart-bar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Rendimiento</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ rendimiento }}</v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="4">
        <v-card dark outlined :color="modoOscuro ? '#385F73' : 'blue-grey lighten-5'">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="$emit('continuar')"
              >Continuar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "resultadoEjercicio",
  props: ["correctas", "incorrectas", "omitidas", "rendimiento"],
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
};
</script>
