<template>
  <v-toolbar :color="color" flat dark height="100">
    <v-list-item>

      <v-list-item-action @click="goBack()">
          <v-btn small icon outlined>
            <v-icon>mdi-arrow-left-bold</v-icon>
          </v-btn>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          <h2>{{ titulo }}</h2>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <v-icon large>{{ icon }}</v-icon>
      </v-list-item-icon>

    </v-list-item>
  </v-toolbar>
</template>

<script>
export default {
  name: "moduloBanner",
  props: ["color", "titulo", "icon"],
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {
      goBack(){
          this.$router.go(-1);
      }
  },
};
</script>
