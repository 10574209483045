<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear
        :indeterminate="loading"
        :value="progreso"
        height="10"
        color="blue darken-3"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "progresoEjercicioComponent",
  props: ["loading", "progreso"],
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
};
</script>
