<template>
  <v-container class="main-content">
    <progresoEjercicioComponent :loading="loading" :progreso="progreso" />
    <comenzandoComponent
      v-if="!comenzar && !loading"
      @comenzarEjercicio="comenzarEjercicio()"
    />
    <v-row
      v-if="comenzar && !loading && !mostrarResultados"
      align="center"
      style="height: 250px;"
    >
      <v-col align="center" cols="12">
        <v-card
          flat
          :dark="modoOscuro"
          :color="modoOscuro ? '#385F73' : 'blue-grey lighten-5'"
        >
          <table align="center" width="100%">
            <tr height="70">
              <td align="center" colspan="3">
                <div v-if="imprimirReactivo" class="text-box">
                  {{ reactivoActual.centro }}
                </div>
              </td>
            </tr>
            <tr height="50">
              <td
                align="right"
                width="45%"
                :style="{ 'padding-right': `${separacion}px` }"
              >
                <div v-if="imprimirReactivo" class="text-box">
                  {{ reactivoActual.derecha }}
                </div>
              </td>
              <td align="center" width="100">
                <v-icon
                  v-if="imprimirReactivo"
                  small
                  color="blue-grey lighten-3"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </td>
              <td
                align="left"
                width="45%"
                :style="{ 'padding-left': `${separacion}px` }"
              >
                <div v-if="imprimirReactivo" class="text-box">
                  {{ reactivoActual.izquierda }}
                </div>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
      <v-col align="center" cols="12">
        <v-btn small color="primary" @click="validarReactivo()">Iguales</v-btn>
      </v-col>
    </v-row>

    <resultadoEjercicioComponent
      v-if="mostrarResultados"
      :correctas="clickCorrecto"
      :incorrectas="clickIncorrecto"
      :omitidas="omitidas"
      :rendimiento="rendimiento"
      @continuar="$router.go(-1)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import progresoEjercicioComponent from "../progresoEjercicio";
import comenzandoComponent from "../comenzando";
import resultadoEjercicioComponent from "../resultadoEjercicio";

export default {
  name: "AV1",
  props: ["claveEjercicio", "ejercicioData"],
  components: {
    progresoEjercicioComponent,
    comenzandoComponent,
    resultadoEjercicioComponent,
  },
  computed: {
    ...mapGetters(["modoOscuro"]),
    progreso() {
      let progreso = (this.posicionActual / 100) * this.reactivos.length;
      return Math.round(progreso);
    },
  },
  beforeDestroy() {
    this.stopTimmer();
  },
  data() {
    return {
      loading: false,
      comenzar: false,
      imprimirReactivo: false,
      mostrarResultados: false,
      tiempoMuestra: 300,
      tiempoOcultar: 800,
      separacion: 10,
      intervaloAumento: 10,
      aumentoSeparacion: 10,
      reactivosCorrectos: 50,
      reactivos: [],
      posicionActual: -1,
      reactivoActual: {
        letraIzquierda: "X",
        letraCentro: "X",
        letraDerecha: "X",
      },
      clickActivo: false,
      clickCorrecto: 0,
      clickIncorrecto: 0,
      omitidas: 0,
      rendimiento: 0,
      timmer: null,
      timeOut: null,
      tiempo: 0,
    };
  },
  methods: {
    async setEjercicioData() {
      this.tiempoMuestra = this.ejercicioData.tiempoMuestra;
      this.tiempoOcultar = this.ejercicioData.tiempoOcultar;
      this.separacion = this.ejercicioData.separacion;
      this.intervaloAumento = this.ejercicioData.intervaloAumento;
      this.aumentoSeparacion = this.ejercicioData.aumentoSeparacion;
      this.reactivosCorrectos = this.ejercicioData.reactivosCorrectos;
      this.reactivos = this.ejercicioData.reactivos;
    },
    comenzarEjercicio() {
      try {
        this.setEjercicioData();
        this.comenzar = true;
        this.startTimmer();
        setTimeout(() => this.mostrarReactivo(), 1000);
      } catch (error) {
        this.$appError(error.message);
      }
    },
    setReactivo() {
      this.reactivoActual = this.reactivos[this.posicionActual];
    },
    mostrarReactivo() {
      this.posicionActual++;
      if (this.posicionActual < this.reactivos.length) {
        this.setReactivo();
        this.imprimirReactivo = true;
        this.clickActivo = true;
        if (this.posicionActual % this.intervaloAumento == 0)
          this.separacion += this.aumentoSeparacion;
        this.timeOut = setTimeout(
          () => this.ocultarReactivo(),
          this.tiempoMuestra
        );
      } else this.terminarEjercicio();
    },
    ocultarReactivo() {
      this.imprimirReactivo = false;
      this.timeOut = setTimeout(() => this.mostrarReactivo(), this.tiempoOcultar);
    },
    validarReactivo() {
      if (this.clickActivo) {
        const correcto =
          this.reactivoActual.izquierda == this.reactivoActual.centro &&
          this.reactivoActual.izquierda == this.reactivoActual.derecha;
        if (correcto) this.clickCorrecto++;
        else this.clickIncorrecto++;
        this.clickActivo = false;
      }
    },
    terminarEjercicio() {
      this.stopTimmer();
      this.omitidas = this.reactivosCorrectos - this.clickCorrecto;
      this.rendimiento = Math.round(
        (this.clickCorrecto * 100) / this.reactivosCorrectos
      );
      this.rendimiento -= this.clickIncorrecto;
      if (this.rendimiento < 0) this.rendimiento = 0;
      this.mostrarResultados = true;
      //Mandar resultados al servidors
    },
    startTimmer() {
      let el = this;
      this.timmer = setInterval(function() {
        el.tiempo++;
      }, 1000);
    },
    stopTimmer() {
      clearInterval(this.timmer);
      clearTimeout(this.timeOut);
    },
  },
};
</script>

<style>
.main-content {
  min-height: 300px;
}
.text-box {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px !important;
  /* font-weight: bold; */
}
</style>
