<template>
  <v-card
    outlined
    :dark="modoOscuro"
    :color="modoOscuro ? '#385F73' : 'blue-grey lighten-5'"
  >
    <AV1Component
      v-if="ejercicioAV1"
      :claveEjercicio="claveEjercicio"
      :ejercicioData="ejercicioData"
    />
    <AV2Component
      v-if="ejercicioAV2"
      :claveEjercicio="claveEjercicio"
      :ejercicioData="ejercicioData"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AV1Component from "./amplitudVisual/AV1.vue";
import AV2Component from "./amplitudVisual/AV2.vue";

export default {
  name: "ejercicioIndex",
  props: ["claveEjercicio", "ejercicioData"],
  components: {
    AV1Component,
    AV2Component,
  },
  computed: {
    ...mapGetters(["modoOscuro"]),
    ejercicioAV1(){
      return (
        this.claveEjercicio === 'AVP1' ||
        this.claveEjercicio === 'AVL1' ||
        this.claveEjercicio === 'AVH1' 
      );
    },
    ejercicioAV2(){
      return (
        this.claveEjercicio === 'AVP2' ||
        this.claveEjercicio === 'AVL2' ||
        this.claveEjercicio === 'AVH2' 
      );
    }
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
