import { getInstruccionesEjercicioAV } from './amplitudVisual/instruccionesAV.service';

export function getInstruccionesEjercicio(claveEjercicio) {
    try {
        const area = claveEjercicio.substring(0, 2);
        
        if (area == 'AV') return getInstruccionesEjercicioAV(claveEjercicio)

        return [];
    } catch (error) {
        return []
    }
}