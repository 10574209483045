import { getServerRequest } from '@/helpers/serverRequest.helper';
import { store } from '@/store/index';
const httpURL = store.getters.httpURL;

export async function getEjercicioData(moduloRoute, claveEjercicio) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const area = claveEjercicio.substring(0, 2);
    const numero = claveEjercicio.substring(3, 4);
    const url = `${httpURL}/ejercicios/${moduloRoute}/${area}${numero}/${claveEjercicio}`;
    return await getServerRequest(url, config);
}