<template>
  <v-row align="center" style="height: 250px;">
    <v-col cols="12" class="text-center">
        Comenzando en {{ comenzando }}
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "comenzandoComponent",
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      comenzando: 6,
    };
  },
  mounted() {
    this.strartTimeout();
  },
  methods: {
    strartTimeout() {
      if (this.comenzando > 1) {
        this.comenzando--;
        setTimeout(() => this.strartTimeout(), 1000);
      } else {
        this.$emit("comenzarEjercicio");
      }
    },
  },
};
</script>
