<template>
  <v-container>
    <v-row v-if="loading">
      <v-col cols="12">
        <ejercicioLoadingComponent />
      </v-col>
    </v-row>

    <v-row v-else dense>
      <v-col cols="12">
        <v-card :color="modoOscuro ? '#002969' : 'grey lighten-3'" outlined>
          <ejercicioBannerComponent
            :color="modoOscuro ? modulo.colorOscuro : modulo.colorClasico"
            :titulo="modulo.titulo"
            :icon="modulo.icon"
          />
        </v-card>
      </v-col>

      <v-col cols="12">
        <instruccionesEjercicioComponent
          v-if="mostrarInstrucciones"
          :claveEjercicio="claveEjercicio"
          @realizarEjercicio="realizarEjercicioAction"
        />
      </v-col>

      <v-col cols="12">
        <ejerciciosIndexComponent
          v-if="realizarEjercicio"
          :claveEjercicio="claveEjercicio"
          :ejercicioData="ejercicioData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import ejercicioLoadingComponent from './ejercicioLoading.vue';
import ejercicioBannerComponent from './ejercicioBanner.vue';
import instruccionesEjercicioComponent from './instruccionesEjercicio.vue';
import ejerciciosIndexComponent from './ejerciciosIndex.vue';

import {getEjercicioData} from './ejercicios.service';

export default {
  name: "ejercicioView",
  components: {
    ejercicioLoadingComponent,
    ejercicioBannerComponent,
    instruccionesEjercicioComponent,
    ejerciciosIndexComponent
  },
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
      nombreModulo: null,
      modulo: {},
      moduloRoute: '',
      mostrarInstrucciones: true,
      realizarEjercicio: false,
      claveEjercicio: '',
      ejercicioData: null
    };
  },
  created() {
    this.realizarEjercicio = false;
    this.moduloRoute = this.$route.params.moduloRoute;
    this.claveEjercicio = this.$route.params.clave;
    this.cargarEjercicio();
  },
  methods: {
    async cargarEjercicio(){
      try {
        this.loading = true;
        const serverResponse = await getEjercicioData(this.moduloRoute, this.claveEjercicio);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        this.modulo = serverResponse.modulo;
        this.ejercicioData = serverResponse.ejercicioData;
      } catch (error) {
        this.$router.go(-1);
      }
    },
    realizarEjercicioAction(){
      this.mostrarInstrucciones = false;
      this.realizarEjercicio = true;
    }
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
</style>
