<template>
  <v-card :color="modoOscuro ? '#002969' : 'grey lighten-3'" outlined>
    <v-sheet :color="`grey ${modoOscuro ? 'darken-2' : 'lighten-4'}`">
      <v-skeleton-loader
        class="mx-auto"
        max-height="100"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>
    <v-container>
      <v-row dense align="start">
        <v-col cols="12">
          <v-card
            :color="`grey ${modoOscuro ? 'darken-2' : 'lighten-4'}`"
            min-height="400"
          >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ejercicioLoading",
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
};
</script>
