<template>
  <v-card
    outlined
    :dark="modoOscuro"
    :color="modoOscuro ? '#385F73' : 'blue-grey lighten-5'"
  >
    <v-card-title>
      <v-spacer></v-spacer>
      Instrucciones
      <v-spacer></v-spacer>
    </v-card-title>

    <v-container>
      <v-row dense justify="center">
        <v-col cols="12" lg="8" md="8">
          <v-stepper
            v-model="step"
            vertical
            :style="{ 'background-color': modoOscuro ? '#546E7A' : '#CFD8DC' }"
          >
            <div v-for="(instruccion, index) of instrucciones" :key="index">
              <v-stepper-step :step="index + 1" :complete="step > index + 1">
                {{ instruccion.titulo }}
              </v-stepper-step>

              <v-stepper-content :step="index + 1">
                <v-card
                  class="mb-12"
                  min-height="100px"
                  flat
                  :color="
                    modoOscuro ? 'blue-grey darken-2' : 'blue-grey lighten-5'
                  "
                >
                  <v-card-text>
                    {{ instruccion.texto }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      x-small
                      :outlined="!modoOscuro"
                      :dark="modoOscuro"
                      @click="step = index + 2"
                    >
                      Siguiente
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </div>
          </v-stepper>
        </v-col>

        <!-- <v-col
          cols="12"
          lg="8"
          md="8"
          v-for="(instruccion, index) of instrucciones"
          :key="index"
        >
          <v-card
            :color="modoOscuro ? '#385F73' : 'blue-grey lighten-5'"
            :dark="modoOscuro"
            flat
          >
            <div class="d-flex flex-no-wrap">
              <v-avatar class="ma-3" size="90" color="purple" tile>
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <div>
                <v-card-title class="text-body-1">
                  {{ instruccion.texto }}
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="realizarEjercicio()">Comenzar</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getInstruccionesEjercicio } from "./instrucciones.service";

export default {
  name: "instruccionesEjercicio",
  props: ["claveEjercicio"],
  components: {},
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
      step: 0,
      instrucciones: [],
    };
  },
  created() {
    this.instrucciones = getInstruccionesEjercicio(this.claveEjercicio);
  },
  mounted() {
    this.step = 1;
  },
  methods: {
    realizarEjercicio() {
      this.$emit("realizarEjercicio");
    },
  },
};
</script>
